
import { defineComponent } from 'vue'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import { useRouter } from 'vue-router'
import urlJoin from 'url-join'
import { CERTIFICATE_DOMAIN } from '@/constants'
import { getRedirectUrl } from '@/functions/pure/utils'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: {
    MdiAlert,
  },

  setup() {
    const { currentRoute } = useRouter()
    const onClick = () => {
      const redirectTo = urlJoin(
        location.origin,
        currentRoute.value.redirectedFrom?.path || ''
      )
      const url = getRedirectUrl(CERTIFICATE_DOMAIN, redirectTo)
      location.replace(url)
    }
    const { t } = useTranslation()

    return {
      t,
      onClick,
    }
  },
})
