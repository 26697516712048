<template>
  <main class="flex items-center justify-center h-screen px-4 bg-gray-100">
    <div
      class="flex flex-col items-center justify-center sm:flex-row sm:items-center"
    >
      <p class="font-semibold text-red-500 text-9xl">403</p>
      <div class="sm:ml-4 text-center sm:text-left">
        <h1 class="flex justify-center sm:justify-start items-center">
          <mdi-alert class="text-red-500 mr-1" />
          <span class="text-xl font-medium text-gray-600 sm:text-2xl">
            {{ t('Forbidden.') }}
          </span>
        </h1>
        <p class="text-base font-normal text-gray-600">
          {{
            t(
              'The page cannot be displayed because an access permission error has occurred.'
            )
          }}
        </p>
        <button
          class="mt-3 shadow outline-none focus:outline-none focus:ring-2 bg-blue-500 text-white hover:bg-opacity-80 hover:shadow-md py-1 px-2 rounded transition duration-200"
          @click="onClick"
        >
          {{ t('Try Again') }}
        </button>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import { useRouter } from 'vue-router'
import urlJoin from 'url-join'
import { CERTIFICATE_DOMAIN } from '@/constants'
import { getRedirectUrl } from '@/functions/pure/utils'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: {
    MdiAlert,
  },

  setup() {
    const { currentRoute } = useRouter()
    const onClick = () => {
      const redirectTo = urlJoin(
        location.origin,
        currentRoute.value.redirectedFrom?.path || ''
      )
      const url = getRedirectUrl(CERTIFICATE_DOMAIN, redirectTo)
      location.replace(url)
    }
    const { t } = useTranslation()

    return {
      t,
      onClick,
    }
  },
})
</script>
